<template>
  <div
      class="item reportName"
      title="go to latest report release"
      @click="$router.push(`${collectionName}/${getDocumentAlias(document)}`)"
  >
    <div class="labeled icon icon3x">
      <NewspaperVariantIcon></NewspaperVariantIcon>
    </div>
    <span class="title">{{title}}</span>
  </div>
</template>

<script>
import { capitalize } from "../../../../../lib/helpers"

export default {
  name: "vReportListItem",
  props: {
    collectionName:  {
      type: String,
      required: true
    },
    name: {
      type: String,
      required: true
    }
  },
  computed: {
    title() {
      let title
      title = this.name
          .split('_')
          .map(word => capitalize(word))
          .join(' ')
      return title ?? 'loading...'
    }
  },
  methods: {
    getDocumentAlias() {
      return this.name.replaceAll(' ', '_')
    }
  }
}
</script>

<style scoped>
.item {
  cursor: pointer!important;
  display: flex;
  flex-direction: row;
  align-items: center;
  /*justify-content: center;*/
  margin-bottom: 1rem;
}
.item h1 {
  margin-bottom: 0;
  margin-left: 1rem;
}
.title {
    margin-left: .5rem;
    font-size: 150%;
  }
</style>
