<template>
  <div class="list">
    <vReportListItem
        v-for="(document, index) in reverseSortedDocuments"
        :key="index"
        :collectionName="collectionName"
        :name="document.name"
    ></vReportListItem>
  </div>
</template>

<script>
  import vReportListItem          from "@/components/DocumentCollection/reports/vReportListItem"
  import DocumentCollectionsMixin from "@/mixins/documentCollections/DocumentCollectionsMixin"

  export default {
    name: "vReportList",
    components: {
      vReportListItem,
    },
    mixins: [
      DocumentCollectionsMixin
    ],
    mounted() {
      this.loadCollection(this.collectionName)
    },
    methods: {
      createReport(event, payload) {
        payload = {
          name: 'New Report',
          ...payload,
          collectionName: this.collectionName,
        }
        this.createDocument(payload)
      },
    }
  }
</script>

<style scoped>


</style>
