<template>
  <div class="page__content theme--normal" id="content">
    <div class="page__content__block">
      <div class="news">

        <vDocument
          collectionName="pageTexts"
          documentName="reports_heading"></vDocument>

        <div
            class="actions"
            v-if="user"
        >

          <vLabeledIconButton
              v-if="user"
              label="Create a New Report"
              icon="NewspaperPlusIcon"
              :iconSizePx="96"
              fontSize="1.2rem"
              @onClick="askForReportName"
          />

          <div>

            <vNameNewItem
                v-if="reportNameInputIsVisible"
                disableTriggerButton="true"
                heading="Name the new <strong>Report</strong> (step 1/2)"
                buttonLabel="Name the new Report Series"
                :takenNames="documentNames"
                :disabled="versionNameInputIsVisible"
                typeName="Report"
                @onValidName="getReportNameAndAskForVersionName"
            ></vNameNewItem>

            <vNameNewItem
                v-if="versionNameInputIsVisible"
                disableTriggerButton="true"
                heading="Name the first <strong>Release</strong> (step 2/2)"
                buttonLabel="Name the report's first Release"
                :reservedNames="reservedVersionNames"
                :takenNames="versionNames"
                typeName="Release"
                @onValidName="getVersionNameAndCreateReport"
            ></vNameNewItem>

          </div>
        </div>

        <div
          v-if="reports.length"
          class="list"
        >
          <vReportList :collectionName="collectionName"/>
        </div>

        <p v-else>
          No reports yet...
        </p>

      </div>
    </div>
  </div>
</template>

<script>
  import vReportList from "@/components/DocumentCollection/reports/vReportList"
  import DocumentCollectionsMixin from "@/mixins/documentCollections/DocumentCollectionsMixin"

  export default {
    name: "Reports",
    components: {
      vReportList,
    },
    mixins: [
      DocumentCollectionsMixin
    ],
    props: {
      collectionName: {
        type: String,
        default: 'reports'
      }
    },
    data () {
      return {
        reportNameInputIsVisible: false,
        versionNameInputIsVisible: false,
        reservedVersionName: ['latest'],
        newReportName: '',
        newVersionName: '',
      }
    },
    computed: {
      alias() {
        return this.title.replaceAll(' ', '_') // todo: cover all url relevant chars
      },
      defaultContent() {
        const improvisedTitle = this.newReportName.replaceAll('_', ' ')
        return `# ${ improvisedTitle }`
      },
      reports: {
        get() {
          return this.sortedDocuments
        }
      },
    },
    mounted() {
      this.resetDialogs()
      this.loadCollection(this.collectionName)
    },
    methods: {
      askForReportName() {
        this.reportNameInputIsVisible = this.user
      },
      createReport() {
        this.createDocument({
          collectionName: this.collectionName,
          name: this.newReportName,
          versionName: this.newVersionName,
          content: this.defaultContent,
        })
        this.resetDialogs()
      },
      getReportNameAndAskForVersionName(reportName) {
        this.newReportName = reportName
        this.showFirstVersionNameInput()
      },
      getVersionNameAndCreateReport(versionName) {
        this.newVersionName = versionName
        this.createReport()
      },
      resetDialogs() {
        this.reportNameInputIsVisible = false
        this.versionNameInputIsVisible = false
        this.validationErrorMessage = false
        this.newReportName = ''
        this.newVersionName = ''
      },
      showFirstVersionNameInput() {
        this.versionNameInputIsVisible = true
      },

    },
  }
</script>

<style scoped>
  .column {
    margin-top: .5rem;
    display: grid;
    grid-template-rows: 1fr 1fr;
    grid-row-gap: .5rem;
  }
  .reports {
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  .list {
    margin-top: 1rem;
  }
  .list .item {
    max-width: 38rem;
    margin-block-end: 1em;
    cursor: pointer;
  }
  .validatedInput {
    display: grid;
    grid-template-rows: repeat(1fr);
    grid-row-gap: 0.2rem;
  }

</style>
